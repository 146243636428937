<template>
  <div class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">订单管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">课程中心</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">详情</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <div class="z_top">
              <img class="z_left" :src="infoDta.logoURL" alt="" />
              <div class="z_right">
                <p class="z_right_1">{{ infoDta.courseName }}</p>
                <p class="z_right_2">
                  {{ infoDta.projectTrainTypeName || "" }}
                </p>
                <p class="z_right_3">
                  适用地区：<span>{{ infoDta.projectAreaPath }}</span>
                </p>
                <p class="z_right_4">￥{{ infoDta.price }}</p>
                <el-button
                  class="bgc-bv"
                  style="margin-top: 20px; margin-left: 0"
                  round
                  size="small"
                  @click="immediatelyBuy"
                  >立即购买</el-button
                >
              </div>
            </div>
            <el-tabs class="z_bottom" v-model="activeName">
              <el-tab-pane label="课程详情" name="curriculumDetails"
                ><div v-html="infoDta.context"></div
              ></el-tab-pane>
              <el-tab-pane label="课程目录" name="curriculumCatalogue"
                ><section class="chapterl-trees detail">
                  <el-tree
                    :data="treeData"
                    node-key="id"
                    :expand-on-click-node="false"
                    v-if="treeData"
                  >
                    <div
                      class="custom-tree-node flexac"
                      slot-scope="{ node, data }"
                    >
                      <p v-if="data.level === 1">
                        <span style="width: 44px; text-align: left">
                          <span class="level" style="margin-right: 10px">
                            <!-- 一级 -->
                            {{ data.chapterNum }}
                          </span>
                        </span>
                        <span>{{ node.label }}</span>
                      </p>
                      <template v-else-if="data.level === 2">
                        <span style="width: 44px; text-align: left">
                          <span class="level" style="margin-right: 10px">
                            {{ data.chapterNum }}
                          </span>
                        </span>
                        <span
                          v-if="data.list.length > 0"
                          style="
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                          "
                        >
                          <i
                            class="courseIcon"
                            v-if="data.list[0].kpointId"
                          ></i>
                          <i
                            class="paperIcon"
                            v-else-if="data.list[0].paperId"
                          ></i>
                        </span>
                        <span
                          v-else
                          style="
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                          "
                        >
                          <i class="nullIcon"></i>
                        </span>
                        <span class="ellipsis" style="margin-right: 62px">{{
                          data.label
                        }}</span>
                        <span>{{ data.kpointDurationStr }}</span>
                        <span class="trees-btns" :canDarg="data.list.length">
                          <template v-if="data.list.length">
                            <span
                              class="draggable-item handle"
                              v-for="element in data.list"
                              :key="element.name"
                            >
                              <el-button
                                  style="margin-left: 16px!important;padding: 4px 15px!important"
                                class="showbtn bgc-bv"
                                size="mini"
                                v-if="data.trySee"
                                @click.stop="() => showVideo(data)"
                                >试看</el-button
                              >
                            </span>
                          </template>
                        </span>
                      </template>
<!--                      <template v-else>-->
<!--                        <span-->
<!--                          class="textellipsis"-->
<!--                          :title="data.title + ':' + data.catalogName"-->
<!--                          >{{ data.title }}：{{-->
<!--                            data.catalogName ||-->
<!--                            (data.title == "授课讲师" ? "无讲师" : "无习题")-->
<!--                          }}</span-->
<!--                        >-->
<!--                        <el-button-->
<!--                          type="text"-->
<!--                          @click="showPaper(data)"-->
<!--                          size="mini"-->
<!--                          v-show="data.paperId"-->
<!--                          class="dataqualificationImageId"-->
<!--                          >预览</el-button-->
<!--                        >-->
<!--                        <el-button-->
<!--                          type="text"-->
<!--                          @click="show(data)"-->
<!--                          size="mini"-->
<!--                          v-show="data.qualificationImageId"-->
<!--                          class="dataqualificationImageId"-->
<!--                          >预览</el-button-->
<!--                        >-->
<!--                      </template>-->
                    </div>
                  </el-tree>
                </section>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>
    </div>
    <!-- 视频 - 弹框 -->
    <el-dialog
      v-if="dialogVisible1"
      :title="videotitle"
      :visible.sync="dialogVisible1"
      width="60%"
      :before-close="handleClose"
    >
      <player_2
        :videoId="videoId"
        :courseId="courseId"
        :kpointId="kpointId"
        :videoTime="videoTime"
        :kpointSource="kpointSource"
        v-if="videoType"
        ref="player2"
      />
      <player_1
        :videoId="videoId"
        :courseId="courseId"
        :kpointId="kpointId"
        :videoTime="videoTime"
        :kpointSource="kpointSource"
        v-else
        ref="player2"
      />
    </el-dialog>
    <!-- 讲师 - 弹框 -->
    <el-dialog
      :title="'授课讲师：' + teacherName"
      :visible.sync="dialogVisible"
      width="40%"
      top="2%"
    >
      <el-image
        :src="src"
        class="imgBoxs"
        oncontextmenu="return false;"
        ondragstart="return false;"
      />
    </el-dialog>
    <!-- 试卷 - 弹框 -->
    <PaperShow ref="PaperShow" />
    <!-- 购买 - 弹框 -->
    <el-dialog
      title="购买课程订单确认"
      :visible.sync="bugdialogVisible"
      :show-close="false"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      width="1000px"
      top="5%"
      ><el-table :data="orderListData" style="width: 100%">
        <el-table-column
          prop="logoURL"
          show-overflow-tooltip
          label="课程封面"
          align="center"
          width="100"
        >
          <template slot-scope="scope">
            <img style="width: 100%" :src="scope.row.logoURL" alt="" />
          </template>
        </el-table-column>
        <el-table-column
          prop="courseName"
          show-overflow-tooltip
          label="课程名称"
          align="center"
          width="200"
        >
        </el-table-column>
        <el-table-column
          prop="lessonNum"
          show-overflow-tooltip
          label="课时"
          width="50"
        >
        </el-table-column>
        <el-table-column
          prop="projectAreaPath"
          show-overflow-tooltip
          label="适用区域"
          align="center"
          min-width="120"
        >
        </el-table-column>
        <el-table-column
          prop="price"
          show-overflow-tooltip
          label="单价"
          width="100"
        >
        </el-table-column>
        <el-table-column
          prop="num"
          show-overflow-tooltip
          label="数量"
          width="200"
        >
          <template slot-scope="scope">
            <el-input-number
              v-model="scope.row.num"
              @change="handleChange(scope.row.num, scope.$index)"
              :precision="0"
              :step="1"
              :min="1"
              label="数量"
            ></el-input-number>
          </template>
        </el-table-column>
        <el-table-column
          prop="totalAmountPrice"
          show-overflow-tooltip
          label="金额"
          width="120"
        >
        </el-table-column>
      </el-table>
      <div v-if="paymentMode == 1" id="qrcode" ref="qrcode"></div>
      <div v-if="paymentMode == 2" class="zfbzf"></div>
      <div v-if="paymentMode == 3" class="dgzf">
        <template v-if="!orderButtonhandle">
          <p>汇款信息：</p>
          <p>单位名称：北京晟融信息技术有限公司</p>
          <p>开户行及账号：中国民生银行股份有限公司北京苏州街支行  154322354</p>
          <p>开户行行号：305100001258</p>
          <p class="dgzf_t">注意：</p>
          <p class="dgzf_t">
            1.请在回款完成后，在【订单列表】订单处及时上传汇款凭证，上传成功后正常工作时间会在三小时内确认，非工作时间会在上班内及时确认。
          </p>
          <p class="dgzf_t">
            2.请在汇款时，备注一下订单编号：<span>{{ orderSn }}</span>
            ；便于财务快速确认到账。
          </p>
        </template>
      </div>
      <div class="tsTime">{{ infoDta.buyRemind }}</div>
      <span slot="footer" class="dialog-footer">
        <div class="je">
          订单金额：<span style="color: #ff0d0d; font-size: 18px"
            >￥{{ orderListData[0].finalAmountPrice }}</span
          >
        </div>
        <div class="zffs">支付方式：</div>
        <img
          class="wx_1"
          v-if="paymentMode == 1"
          src="../../assets/wx_z_2.png"
          alt=""
        />
        <img
          v-else
          class="wx_1"
          @click="purchaseType(1)"
          src="../../assets/wx_z_1.png"
          alt=""
        />
        <img
          class="zfb_1"
          v-if="paymentMode == 2"
          src="../../assets/zfb_z_2.png"
          alt=""
        />
        <img
          v-else
          class="zfb_1"
          @click="purchaseType(2)"
          src="../../assets/zfb_z_1.png"
          alt=""
        />
        <img
          class="dgzf_1"
          v-if="paymentMode == 3"
          src="../../assets/dgzf_2.png"
          alt=""
        />
        <img
          v-else
          class="dgzf_1"
          @click="purchaseType(3)"
          src="../../assets/dgzf_1.png"
          alt=""
        />
        <el-button @click="norder" size="small">关 闭</el-button>
        <el-button
          type="primary"
          size="small"
          @click="yorder"
          v-if="orderButtonhandle"
          >确认订单</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import PaperShow from "@/views/resourse/popup/PaperShow";
import player_1 from "@/components/player";
import player_2 from "@/components/playerTrysee";
import QRCode from "qrcodejs2"; // 引入qrcode
import { createSwigger, distorySwigger } from "../../utils/swigger";
export default {
  name: "/web/courseCenterInfo",
  components: {
    PaperShow,
    player_1,
    player_2,
  },
  data() {
    return {
      courseId: this.$route.query.courseId || '', // 课程id
      projectCourseId: this.$route.query.projectCourseId || '', // 班级课程id
      activeName: "curriculumDetails",
      infoDta: {}, // 详情 - 数据
      treeData: [], // 课程目录 - 数据
      orderListData: [{}], // 购买订单列表 - 数据
      chapterSchema: true,
      videoId: "", // 視頻id
      videoType: false, // true-试看；false-直接看
      dialogVisible1: false, // 预览视频 - loading
      videotitle: "", // 预览视频 - loading - 标题
      videoTime: "", // 视频时长
      dialogVisible: false, // 讲师 - loading
      bugdialogVisible: false, // 购买 - loading
      paymentMode: "1", // 选择购买方式:1-微信；2-支付宝；3-对公支付
      orderSn: "", // 购买后生成的订单id
      getInfosSetInterval: null, // 实时获取购买是否成功
      orderButtonhandle: true, // 点击确认订单隐藏按钮
    };
  },
  created() {
    this.getDetail();
  },
  computed: {},
  mounted() {},
  methods: {
    // 获取- 课程详情
    getDetail() {
      this.$post("/run/projectCourse/display", {
        courseId: this.courseId,
        projectCourseId: this.projectCourseId,
      }).then((result) => {
        if (result.status === "0") {
          let data = result.data;
          let list = [];
          if (data.chapterSchema) {
            data.nodes.forEach((element) => {
              let nodeList = [];
              list.push({
                ind: new Date().getTime(),
                label: element.catalogName,
                id: element.courseKpointId,
                children: nodeList,
                level: 1,
                trySee: element.trySee,
                kpointDuration: element.kpointDuration,
                kpointDurationStr: element.kpointDurationStr,
              });
              (element.child || []).forEach((obj) => {
                nodeList.push(this.section2Item(obj));
              });
            });
          } else {
            data.nodes.forEach((element) => {
              list.push(this.section2Item(element));
            });
          }
          this.treeData = list;
          this.mapData(list);
          this.$set(this, "infoDta", data);
          this.regionAgreement(data.projectAreaPath);
        }
      });
    },
    // 处理 - 课程目录的数据
    section2Item(obj) {
      // let children = [];
      // children = [
      //   {
      //     title: "授课讲师",
      //     catalogName: obj.teacherPathName,
      //     courseKpointId: obj.courseKpointId,
      //     sourceCourseKpointId: obj.courseKpointId,
      //     level: 3,
      //     trySee: obj.trySee,
      //     qualificationImageId: obj.qualificationImageId,
      //   },
      //   {
      //     title: "课后习题",
      //     catalogName: obj.paperName,
      //     courseKpointId: obj.courseKpointId,
      //     sourceCourseKpointId: obj.courseKpointId,
      //     level: 3,
      //     trySee: obj.trySee,
      //     paperId: obj.paperId,
      //   },
      // ];
      let listArr = [];
      if (obj.kpointVideoId) {
        obj.list = listArr;
        listArr.push({
          kpointVideoId: obj.kpointVideoId,
          kpointName: obj.kpointName,
          kpointDuration: obj.kpointDuration,
          kpointId: obj.kpointId,
          kpointLessonNum: obj.kpointLessonNum,
          kpointLogo: obj.kpointLogo,
          kpointType: obj.kpointType,
          qualificationId: obj.qualificationId,
          kpointDeleted: obj.kpointDeleted ? obj.kpointDeleted : "", // 课件是否删除了
          progress: 100,
          kpointSource: obj.kpointSource,
          kpointDurationStr: obj.kpointDurationStr,
        });
      } else if (obj.paperId) {
        obj.list = listArr;
        listArr.push({
          progress: 100,
          buyId: obj.buyId || "",
          paperId: obj.paperId,
          paperName: obj.paperName,
        });
      }
      //判断kpointId存在，paperId存在，带试卷的课件，kpointId不存在，paperId存在是单元测试
      // console.log({
      //   ind: new Date().getTime(),
      //   label: obj.catalogName,
      //   id: obj.courseKpointId,
      //   level: 2,
      //   list: listArr,
      //   // children: children,
      //   auditState: obj.auditState,
      //   auditReason: obj.auditReason,
      //   kpointId: obj.kpointId,
      //   kpointDuration: obj.kpointDuration,
      //   kpointDurationStr: obj.kpointDurationStr,
      //   trySee: obj.trySee,
      // })
      if (obj.kpointId) {
        return {
          ind: new Date().getTime(),
          label: obj.catalogName,
          id: obj.courseKpointId,
          level: 2,
          list: listArr,
          // children: children,
          auditState: obj.auditState,
          auditReason: obj.auditReason,
          kpointId: obj.kpointId,
          kpointDuration: obj.kpointDuration,
          kpointDurationStr: obj.kpointDurationStr,
          trySee: obj.trySee,
        };
      } else {
        return {
          ind: new Date().getTime(),
          label: obj.catalogName,
          id: obj.courseKpointId,
          level: 2,
          list: listArr,
          // children: [],
          paperId: obj.paperId,
          paperName: obj.paperName,
          auditState: obj.auditState,
          auditReason: obj.auditReason,
          trySee: obj.trySee,
        };
      }
    },
    // tree - 序号
    mapData(data) {
      data.forEach((item, i) => {
        item["chapterNum"] = Number(i) + 1;
        if(item.children&&item.children.length>0){
          item.children.forEach((el, k) => {
            el["chapterNum"] = Number(i) + 1 + "-" + (Number(k) + 1);
          });
        }
      });

      this.$nextTick(() => {
        this.treeData = data;
      });
    },
    // 初始化判断 - 医院的机构和课程-适用区域是否一致
    regionAgreement(projectAreaPath) {
      this.$post("/biz/projectCourse/hospital/checkArea", {
        projectCourseId: this.$route.query.projectCourseId,
      }).then((res) => {
        if (!res.data.isMatch) {
          this.$confirm(
            "您选择的课程适用于" +
              projectAreaPath +
              "，与您所在的区域不符，购买后可能无法学习!",
            "提示",
            {
              confirmButtonText: "我知道了",
              cancelButtonText: "取 消",
              showCancelButton: false,
              type: "warning",
            }
          ).then(() => {});
        }
      });
    },
    // 立即购买
    immediatelyBuy() {
      this.bugdialogVisible = true;
      this.$set(this.orderListData[0], "logoURL", this.infoDta.logoURL); // 封面
      this.$set(this.orderListData[0], "courseName", this.infoDta.courseName); // 课程名称
      this.$set(
        this.orderListData[0],
        "projectTrainTypeName",
        this.infoDta.projectTrainTypeName
      ); // 课程类型
      this.$set(this.orderListData[0], "lessonNum", this.infoDta.lessonNum); // 课时
      this.$set(
        this.orderListData[0],
        "projectAreaPath",
        this.infoDta.projectAreaPath
      ); // 适用地区
      this.$set(this.orderListData[0], "price", this.infoDta.price); // 单价
      this.$set(this.orderListData[0], "num", 1); // 数量
      this.$set(this.orderListData[0], "totalAmountPrice", this.infoDta.price); // 金额
      this.$set(this.orderListData[0], "finalAmountPrice", this.infoDta.price); // 最终价格
    },
    // 输入数量
    handleChange(val, index) {
      this.orderListData[index].totalAmountPrice = (
        this.orderListData[index].price * val
      ).toFixed(2);
      this.orderListData[index].finalAmountPrice = (
        this.orderListData[index].price * val
      ).toFixed(2);
    },
    // 关闭订单
    norder() {
      this.bugdialogVisible = false;
      clearInterval(this.getInfosSetInterval);
      this.getInfosSetInterval = null;
      this.paymentMode = 1;
      this.orderButtonhandle = true;
      this.orderSn = "";
    },
    // 选择购买方式；1-微信；2-支付宝
    purchaseType(type) {
      if (this.orderButtonhandle) {
        this.paymentMode = type;
        if (type != 1 && typeof this.$refs.qrcode != "undefined") {
          clearInterval(this.getInfosSetInterval);
          this.getInfosSetInterval = null;
          this.$refs.qrcode.innerHTML = "";
        }
      }
    },
    // 确认订单 - paymentMode:1-微信；2-支付宝；3-对公支付
    yorder() {
      this.orderButtonhandle = false;
      // 判断是否可以购买
      this.$post("/run/projectCourse/checkCanBuy", {
        projectCourseId: this.projectCourseId,
      }).then((okBuy) => {
        maiba();
      });
      // 可以购买购买
      let maiba=()=>{
        if (this.paymentMode == 1) {
          clearInterval(this.getInfosSetInterval);
          this.getInfosSetInterval = null;
          this.$refs.qrcode.innerHTML = "";
          this.$post(
            "/wechat/pay/unified/order",
            {
              productId: this.$route.query.projectCourseId,
              productNumber: this.orderListData[0].num,
              productType: 2,
              tradeType: "NATIVE",
            },
            3000,
            true,
            2
          ).then((res) => {
            this.orderSn = res.data.prepayId;
            this.crateQrcode(res.data.codeURL);
          });
          // 支付宝支付
        } else if (this.paymentMode == 2) {
          const baseURL =
            process.env.VUE_APP_URL == "development"
              ? "http://192.168.3.112:9000/question-lib"
              : process.env.NODE_ENV == "development"
              ? "http://192.168.3.112:9000/question-lib"
              : "https://anzhi.bjsrxx.com/question-lib";
          window.location.href =
            baseURL +
            "/ali/pay/unified/order?productId=" +
            this.$route.query.projectCourseId +
            "&productNumber=" +
            this.orderListData[0].num +
            "&productType=2" +
            "&token=" +
            this.$store.state.token +
            "&returnUrl=" +
            encodeURIComponent(
              location.protocol +
                "//" +
                location.host +
                "/#/web/courseCenterInfo/paySuccess"
            );
        } else if (this.paymentMode == 3) {
          this.$post(
            "/transfer/pay/unified/order",
            {
              productId: this.$route.query.projectCourseId,
              productNumber: this.orderListData[0].num,
              productType: 2,
            },
            3000,
            true,
            2
          ).then((res) => {
            if (res.status != "0") {
              this.$message({
                message: res.message,
                type: "warning",
              });
            } else {
              this.orderSn = res.data.orderSn;
              this.$message({
                message: res.message,
                type: "success",
              });
            }
          });
        } else {
          this.$message({
            message: "请选择购买方式!",
            type: "warning",
          });
        }
      }
    },
    // 生成二维码
    crateQrcode(codeURL) {
      this.$refs.qrcode.innerHTML = "";
      this.qr = new QRCode("qrcode", {
        width: 150,
        height: 150, // 高度
        text: codeURL, // 二维码内容
        render: "canvas", // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
      });
      this.getInfosSetInterval = setInterval(() => {
        this.getInfos();
      }, 3000);
    },
    // 实时获取购买是否成功
    getInfos() {
      this.$post(
        "/order/drp-order-main/detail",
        {
          orderSn: this.orderSn,
        },
        3000,
        true,
        2
      ).then((res) => {
        if (res.data.orderStatus == 1) {
          this.bugdialogVisible = false;
          clearInterval(this.getInfosSetInterval); // 清除定时器
          this.getInfosSetInterval = null;
          res.data.paymentMethod = this.paymentMode;
          let jsonData = JSON.stringify(res.data);
          this.$router.push({
            path: "/web/courseCenterInfo/paySuccess",
            query: {
              jsonData,
            },
          });
        }
      });
    },
    // 预览 - 试题/视频
    showVideo(data) {
      let _this = this;
      // 预览视频
      if (data.list[0].kpointVideoId) {
        _this
          .$post("/biz/courseware/auth", {
            videoId: data.list[0].kpointVideoId,
          })
          .then((result) => {
            _this.videoId = data.list[0].kpointVideoId;
            _this.videoType = result.data.trySee;
            _this.videoTime = result.data.duration;
            _this.videotitle = data.label;
            _this.courseId = _this.courseId;
            _this.kpointId = data.kpointId;
            _this.kpointSource = data.list[0].kpointSource;
            _this.dialogVisible1 = true;
          });
      }
      if (!data.list[0].kpointVideoId && data.paperId) {
        _this.showPaper(data);
      }
    },
    // 预览试题
    showPaper(data) {
      this.$refs.PaperShow.showPopUp(data.paperId);
    },
    // 预览 - 讲师图片
    show(data) {
      this.dialogVisible = true;
      this.teacherName = data.catalogName;
      this.src = data.qualificationImageId;
    },
    // 关闭 - 预览视频
    handleClose() {
      this.$refs.player2.dd();
      distorySwigger();
      this.dialogVisible1 = false;
    },
  },
  // 离开这个页面也要清除
  beforeDestroy() {
    clearInterval(this.getInfosSetInterval); // 清除定时器
    this.getInfosSetInterval = null;
  },
  watch: {},
};
</script>
<style lang="less" scoped>
.z_top {
  overflow: hidden;
  .z_left {
    width: 360px;
    height: 180px;
    display: block;
    float: left;
  }
  .z_right {
    float: left;
    margin-left: 20px;
    p {
      margin-bottom: 10px;
    }
    .z_right_1 {
      font-weight: 600;
      font-size: 18px;
    }
    .z_right_3 {
      span {
        color: #6d6d6d;
      }
    }
    .z_right_4 {
      color: #f56c6c;
      font-weight: 600;
      font-size: 18px;
    }
  }
}
.courseIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  display: inline-block;
  background: url("../../assets/courseIcon.png") no-repeat;
  background-size: cover;
}
.paperIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  display: inline-block;
  background: url("../../assets/paperIcon.png") no-repeat;
  background-size: cover;
}
.nullIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  display: inline-block;
}
.level {
  padding: 3px;
  border-radius: 6px;
  color: #333;
  background-color: #e0f2ff;
  min-width: 20px;
  text-align: center;
}
.detail {
  .ellipsis {
    display: inline-block;
    width: 30rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 39px;
  }
}
.el-input-number {
  line-height: 30px;
}
.zfbzf {
  margin-top: 45px;
  margin-bottom: 25px;
  height: 150px;
}
.dgzf {
  margin-top: 20px;
  height: 200px;
  p {
    line-height: 30px;
  }
  .dgzf_t {
    color: red;
    span {
      font-size: 18px;
      color: #e6a23c;
    }
  }
}
.tsTime {
  margin-top: 20px;
  color: #5c73ff;
}
.dialog-footer {
  width: 100%;
  border-top: 1px solid #ebebeb;
  .je {
    text-align: left;
    margin: 20px 0;
  }
  .zffs {
    float: left;
    margin-top: 8px;
  }
  .wx_1 {
    float: left;
    cursor: pointer;
    vertical-align: middle;
    width: 106px;
    margin-right: 6px;
  }
  .zfb_1 {
    float: left;
    cursor: pointer;
    vertical-align: middle;
    width: 106px;
    margin-right: 6px;
  }
  .dgzf_1 {
    float: left;
    cursor: pointer;
    vertical-align: middle;
    width: 106px;
  }
}

#qrcode {
  width: 150px;
  height: 150px;
  margin: auto;
  margin-top: 45px;
  margin-bottom: 25px;
}
</style>